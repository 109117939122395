@import '../responsive';

.container {
  background-color: #050b30;

  .container__menu {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 64px;
    padding: 32px 16px;

    @include responsive(mobile-large) {
      padding: 32px 24px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include responsive(pc-small) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
