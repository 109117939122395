@import '../textTypography.scss';
@import '../../variables';

.container {
  background-color: $primary;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 6.4px;
  width: 152px;
  height: 48px;
  align-items: center;
  justify-content: center;

  &:hover {
    color: white;
  }

  .container__logo {
    margin-right: 12px;
    display: flex;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
    }
  }

  .container__content {
    .content__text {
      margin: 0;

      @include textTypography(Inter, 11px, normal, 400, 16px, -0.3px, left);
    }

    .content__title {
      margin: 0;

      @include textTypography(Inter, 16px, normal, 600, 22px, -0.4px, left);
    }
  }
}
