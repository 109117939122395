@import '../textTypography';
@import '../../variables';
@import '../responsive';

.container {
  background-color: $blue-darkest;
  padding: 40px 16px 32px 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;

  @include responsive(mobile-large) {
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 24px 32px 24px;
  }

  @include responsive(pc-small) {
    grid-template-columns: auto 299px 379px;
  }

  @include responsive(pc) {
    grid-template-columns: auto 367px 466px;
  }

  @include responsive(pc-large) {
    grid-template-columns: auto 502px 633px;
  }

  .container__logo {
    @include responsive(mobile-large) {
      grid-column: 1 / span 2;
    }

    @include responsive(pc-small) {
      grid-column: 1;
    }
  }

  p {
    margin: 0px;

    color: #acaeb7;
    @include textTypography(Inter, 14px, normal, 400, 22px, 0, left);

    &.container__info {
    }

    &.container__address {
    }
  }
}
