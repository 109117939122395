$primary-dark: #09008a;
$primary: #1e00d5;
$danger: #ff4136;

$blue-darkest: #000420;
$blue-light: #d1e8ff;
$blue-lighter: #f0fffe;
$blue-lightest: #ebebed;
$blue-lightest-more: #dfe4ea;

$purple: #9879fc;
$purple-light: #f0ebff;

$text-blue-darker: #050b30;
$text-blue-dark: #18214d;
$text-blue-dark-light: #262b4b;
$text-blue-dark-lighter: #3e4462;
$text-blue-dark-lightest: #53576f;

$try-gold-button: linear-gradient(82.89deg, #33def2 5.55%, #1e7ad5 50%, #1e00d5 94.45%);
