@import '../textTypography';
@import '../../variables';
@import '../responsive';

.container {
  background-color: $blue-darkest;

  .container__text {
    padding: 24px 0;
    margin: 0 16px;
    color: #acaeb7;
    border-top: 1px solid #262b4b;

    @include responsive(mobile-large) {
      margin: 0 24px;
    }

    @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);
  }
}
