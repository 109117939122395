@import '../textTypography';
@import '../../variables';
@import '../responsive';

.container {
  &:nth-child(2) {
    margin-top: -104px;

    @include responsive(mobile-large) {
      margin-top: 0;
    }
  }

  .container__title {
    display: flex;
    align-items: center;

    h4 {
      color: #d0d1d6;
      margin-bottom: 16px;
      margin-right: 8px;

      @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);
    }

    .container__badge {
      color: white;
      background-color: $primary;
      border-radius: 4px;
      padding: 1px 8px;

      @include textTypography(Inter, 12px, normal, 400, 20px, 0, left);
    }
  }

  .container__menu {
    list-style: none;
    padding: 0;
    margin: 0;

    .container__item {
      color: #acaeb7;
      padding: 4px 0;
      display: flex;

      .container__item__icon {
        display: flex;
        align-items: center;
        @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);

        svg {
          margin-right: 9px;
          color: #53576f;
        }
      }

      a {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;

        @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);

        span {
          margin-right: 8px;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .container__badge {
          color: white;
          background-color: $primary;
          border-radius: 4px;
          padding: 1px 8px;
          margin: 0;

          @include textTypography(Inter, 12px, normal, 400, 20px, 0, left);
        }
      }
    }

    .container__item__more {
      color: #acaeb7;
      padding: 4px 0;
      padding-top: 16px;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);
    }
  }
}
